@import "./import.scss";

.flex1 {
  flex: 1;
}

.flex {
  display: flex;
  &.column {
    flex-direction: column;
  }
}

.card {
  background-color: white;
  border-radius: 20px;
  padding: 14px;
  box-shadow: 2px 2px 6px 1px #00000044;
  .inner {
    border-radius: 10px;
    background-color: $background;
  }
}

.italic {
  font-style: italic;
}

.center {
  text-align: center;
}

.shadow {
  box-shadow: 3px 3px 6px 0px #00000033;
}

.title {
  font-weight: bold;
  font-size: 120%;
}

.button {
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  cursor: pointer;
  &:hover {
    background-color: #ddd;
  }
}

.row {
  background-color: white;
  min-height: 40px;
  padding-left: 8px;
  padding-right: 8px;
  margin-bottom: 8px;
  display: flex;
  align-items: center;
  gap: 12px;
  .img {
    font-size: 0;
    img {
      width: 60px;
      height: 40px;
      margin-top: 8px;
      margin-bottom: 8px;
      object-fit: cover;
    }
  }
}

a {
  color: inherit;
  text-decoration: none;
}

.actionLink {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: underline;
  height: 40px;
}
