@import "../../import.scss";

.TierAntworten {
}
.answers {
  display: flex;
  gap: 8px;
  .ans {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #ddd;
    padding: 5px;
    min-width: 80px;
    cursor: pointer;
    &:hover {
      background-color: #aaa;
    }
    &.active {
      background-color: aquamarine;
    }
    &.selected {
      background-color: aquamarine;
    }
  }
}
