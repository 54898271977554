/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on December 13, 2021 */

@font-face {
  font-family: "nexa_rust_script_t00";
  src: url("/fonts/nexarustscriptt-00-webfont.woff2") format("woff2"),
    url("/fonts/nexarustscriptt-00-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "nexa";
  src: url("/fonts/nexarustscripts-00-webfont.woff2") format("woff2"),
    url("/fonts/nexarustscripts-00-webfont.woff") format("woff");
  font-weight: normal;
  font-style: italic;
}


@font-face {
  font-family: "nexa";
  src: url("/fonts/nexarustscriptb-00-webfont.woff2") format("woff2"),
    url("/fonts/nexarustscriptb-00-webfont.woff") format("woff");
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: "nexa";
  src: url("/fonts/nexarustsans-black-webfont.woff2") format("woff2"),
    url("/fonts/nexarustsans-black-webfont.woff") format("woff");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "nexa_rust_handmadeextended";
  src: url("/fonts/nexarusthandmade-extended-webfont.woff2") format("woff2"),
    url("/fonts/nexarusthandmade-extended-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "nexa";
  src: url("/fonts/nexa_bold-webfont.woff2") format("woff2"),
    url("/fonts/nexa_bold-webfont.woff") format("woff");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "nexa";
  src: url("/fonts/fontfabric_-_nexabook-webfont.woff2") format("woff2"),
    url("/fonts/fontfabric_-_nexabook-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "nexabold";
  src: url("/fonts/fontfabric_-_nexabold-webfont.woff2") format("woff2"),
    url("/fonts/fontfabric_-_nexabold-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
