@import "../../import.scss";

.tierListe {
  .tier {
    color: inherit;
    text-decoration: none;
    height: 40px;
    display: flex;
    align-items: center;
    gap: 12px;
    padding: 12px;
    background-color: white;
    margin-bottom: 12px;
    &:hover{
      background-color: #ddd;
    }
    .img {
      img {
        width: 60px;
        height: 40px;
        object-fit: cover;
      }
    }
    .name {
      font-weight: bold;
    }
  }
}

.search {
  display: flex;
  align-items: center;
  background-color: white;
  height: 40px;
  margin-bottom: 12px;
  padding: 12px;
  .searchInput {
    flex: 1;
    height: 35px;
    border: none;
    outline: none;
    font-size: 200%;
    padding-left: 20px;
  }
}
