@import "../../import.scss";

.tierForm {
  display: flex;
  flex: 1;
  margin-top: 20px;
  .left {
    flex: 1;
  }
  .right {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
}
.multiSelect {
  width: 100%;
  margin-top: 12px;
  height: 30px;
}

.input {
  background-color: white;
  display: flex;
  flex-direction: column;
  margin-bottom: 8px;
  .label {
    margin: 8px;
    margin-bottom: 0;
    font-size: 80%;
  }
  input[type="text"],
  textarea {
    padding: 4px;
    margin: 8px;
    margin-left: 4px;
    margin-right: 4px;
    margin-top: 0;
    outline: none;
    border: none;
    border-bottom: 1px solid #ddd;
    margin-bottom: 8px;
    font-size: 120%;
  }
  textarea {
    min-height: 150px;
  }
}
