@import "../../import.scss";
.login {
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  max-width: 500px;
  margin: 0 auto;
  .loginInput {
    height: 40px;
    margin-bottom: 8px;
    border: none;
    outline: none;
    padding-left: 12px;
    padding-right: 12px;
  }
  button {
    height: 40px;
  }
}
