@import "../../import.scss";

.TrumpCard {
  display: flex;
  position: relative;

  max-width: 500px;
  width: 100%;
  margin: 0px auto;
  .cardInner {
    display: flex;
    flex-direction: column;
    flex: 1;
    background-color: $background;
    overflow: hidden;
    .cardHeader {
      background-color: $green;
      display: flex;
      padding: 10px;
      display: flex;
      color: white;
      height: 40px;
      .name {
        flex: 1;
        .mainName {
          font-size: 120%;
          font-weight: 500;
        }
        .subName {
          font-style: italic;
        }
      }
      .cardIcon {
        background-color: white;
        height: 60px;
        width: 60px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        color: black;
        position: absolute;
        right: 0px;
        top: 0px;
        font-weight: 500;
        font-size: 120%;
        text-align: center;
      }
    }
  }
  .cardInfos {
    display: flex;
    flex-direction: column;
    gap: 4px;
    margin-top: 24px;
    margin-right: 56px;
  }
  .cardInfo {
    display: flex;
    background-color: $darkGrey;
    color: white;
    padding-left: 12px;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    padding-top: 4px;
    padding-bottom: 4px;
    min-height: 30px;
    .text {
      flex: 1;
      min-width: 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      .main {
        font-weight: 500;

        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        min-width: 0;
      }
      .sub {
        color: $orange;
        overflow: hidden;
        text-overflow: ellipsis;
        min-width: 0;
        font-size: 80%;
      }
    }
    .value {
      text-align: right;
      padding-right: 12px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      .main {
        font-weight: 500;
      }
      .sub {
        color: $orange;
        font-size: 80%;
      }
    }
  }

  .otherFacts {
    color: white;
    background-color: $green;
    margin-top: 4px;
    padding: 10px;
    .title {
      font-weight: 500;
    }
  }

  .imgWrap {
    display: flex;
    flex-direction: column;
    position: relative;
    overflow: hidden;
    .cardImage {
      max-height: 250px;
      object-fit: cover;
    }
    .cloud {
      overflow: hidden;
      position: absolute;
      bottom: -10px;
      left: 0px;
      right: 0px;
      color: white;
      background-color: transparent;
      min-width: 500px;
      path {
        fill: $background !important;
      }
    }
  }
}
