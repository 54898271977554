@import "../../import.scss";

.questionInput {
  font-size: 150%;
  margin: 0;
  padding: 0;
  padding: 12px;
  margin-bottom: 12px;
  border: none;
  outline: none;
  border-bottom: 1px solid black;
}

.answer {
  background-color: white;
  padding: 12px;
  font-weight: bold;
  display: flex;
  gap: 24px;
  font-size: 120%;
  margin-bottom: 12px;
  align-items: center;
  input[type="checkbox"] {
    height: 20px;
    width: 20px;
  }
  .answerInput {
    font-size: 120%;
    flex: 1;
    border: none;
    outline: none;
    border-bottom: 1px solid black;
  }
  .deleteAnswerButton {
    cursor: pointer;
    &:hover {
      color: red;
    }
  }
}

.addAnswer {
  text-align: center;
  background-color: white;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  &:hover {
    background-color: #ddd;
  }
}

.deleteQuestionButton {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 200%;
  &:hover {
    color: red;
  }
}
