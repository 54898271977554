@import "../../import.scss";

.frageItem {
  padding: 12px;
  margin-bottom: 8px;
  background-color: white;
  border-radius: 4px;
  display: flex;
  text-decoration: none;
  color: inherit;

  &:hover {
    background-color: #ddd;
    cursor: pointer;
  }
}

.addQuestion {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  text-decoration: underline;
  cursor: pointer;
  padding-left: 12px;
  padding-right: 12px;

  &:hover {
    background-color: #ddd;
  }
}
.addQuestionTextLink {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  height: 40px;
  cursor: pointer;
  &:hover {
    background-color: #ddd;
  }
}

.questionButton {
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #666;
  cursor: pointer;
  &:hover {
    color: black;

    &.trash {
      color: red;
    }
  }
}
