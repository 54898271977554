@import "../../import.scss";

.Header {
  display: flex;
  background-color: $darkGrey;
  color: white;
  height: 80px;
  min-height: 80px;
  max-height: 80px;
  .logo {
    height: 100%;
    display: flex;
    justify-content: flex-end;
    min-width: 0px;
    padding-left: 12px;
    img {
      margin-top: 20px;
      flex: 1;
      min-width: 0px;
      object-fit: contain;
      object-position: 0% 100%;
    }
  }
  .menuButton {
    min-width: 0px;
    width: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 200%;
  }
  .inlineNav {
    flex: 1;
    display: flex;
    align-items: center;
    gap: 24px;
    margin-left: 48px;
    font-size: 80%;
  }
}
