@import "../../import.scss";

.Layout {
  display: flex;
  flex-direction: column;
  flex: 1;
  .inner {
    max-width: 1200px;
    width: 100%;
    margin: 0 auto;
    .wrap {
      padding-left: 12px;
      padding-right: 12px;
    }
  }
}
